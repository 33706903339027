




import { Component, Mixins } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import VKIDMixin from '@/mixins/VKIDMixin'
// store
import AuthModule from '@/store/modules/auth'
import ProfileModule from '@/store/modules/profile'
import MasterEducationModule from '@/store/modules/master/education'

@Component
export default class VKAuth extends Mixins(NotifyMixin, VKIDMixin) {
  private mounted() {
    if (this.payload) {
      AuthModule.setVKPayload(this.payload)
      this.patchVK().then(() => {
        ProfileModule.fetchInformation()
          .then(response => {
            if (response.hasSocials) {
              MasterEducationModule.fetchMasterGroups()
                .catch(this.notifyError)
            }
          })
          .catch(this.notifyError)
          .finally(() => {
            this.$router.replace({ name: 'profile' })
              .catch(() => {return})
          })
      })
        .catch(err => {
          this.notifyError(err)
          this.$router.replace({ name: 'profile', query: { error: 'true' } })
            .catch(() => {return})
        })
    }
  }
}
